import { BrowserRouter, Route, Routes } from "react-router-dom";
import FirstAccess from "./pages/FirstAccess";
import Login from "./pages/Login";
import ResetPassword from "./pages/ResetPassword";

export default function Root(props) {
  const { routes } = props.customProps;

  return (
    <BrowserRouter>
      <Routes>
        <Route path={routes.login.login} element={<Login />} />
        <Route path={
          // routes.login.resetPassword
          "/reset-password"
        } element={<ResetPassword />} />
        <Route path={
          // routes.login.resetPassword
          "/first-access"
        } element={<FirstAccess />} />
      </Routes>
    </BrowserRouter>
  );
}
