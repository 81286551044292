import axios from "axios";
import api from "./api";

class SendCodeResetService {
  /**
   * @description Send code to Reset password
   * @returns {Promise<object | false>} An object when requisition is true or false in error
   */

  async sendCodeReset(emailReset: string): Promise<string | boolean> {
    const body = {
      email: emailReset,
    };

    try {
      const { data } = await api.post("/profile/generateRecoveryCode", body);
      return data;
    } catch (error) {
      return false;
    }
  }

    async validateCode(emailReset: string, codeReset: string): Promise<string | boolean> {
    const body = {
      email: emailReset,
      code: codeReset,
    };

    try {
      const { data } = await api.post("/profile/validateRecoveryCode", body);
      return data;
    } catch (error) {
      return false;
    }
  }

  async resetPassword(
    email: string,
    code: string,
    password: string,
  ): Promise<string | boolean> {
    const body = {
      email,
      code,
      password
    };

    try {
      const { data } = await api.patch("/profile/updatePassword", body);
      return data;
    } catch (error) {
      if(error)
      return "error";
    }
  }
}

export default new SendCodeResetService();
