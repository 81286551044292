import { IError } from "../interfaces/Error.structure";
import api from "./api";

class LoginService {
  async login(email: string, password: string) {
    const body = {
      email,
      password,
    };

    try {
      const { data } = await api.post("auth/login", body);
      return data;
    } catch (error) {
      return {
        error: true,
        message: error.response.data.message as IError
      };
    }
  }
}

export default new LoginService();
