import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background: var(--backgroundPrimary);
`;

export const ToastContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const BoxArea = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 50vw;
  height: 90%;
  background: var(--backgroundSecondary);
  border-radius: 15px;
  min-width: 400px !important;
`;

export const Header = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 12vh;
  background-image: linear-gradient(to right, var(--primary), var(--secondary));
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  padding: 20px;
`;

export const Icon = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100px;
  height: 100px;
  background: var(--backgroundSecondary);
  border-radius: 50%;
  border: none;
  margin-bottom: -12vh;
`;

export const ContainerCardTitle = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 90px;
  text-align: center;
`;

export const CardTitle = styled.text`
  color: var(--gray5);
  font-size: 29px;
  font-weight: 700;
  margin-bottom: 10px;
`;
export const CardSubTitle = styled.text`
  color: var(--gray5);
  font-size: 20px;
  font-weight: 700;
`;

export const FormArea = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 60%;
  padding: 20px;
`;



export const FormLabel = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 55%;
  height: 30px;
  border-radius: 15px;
  border: none;
  margin-bottom: 5px;
  font-size: 14px;
  font-weight: bold;
  color: var(--gray8);
`;

export const FormInput = styled.input`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 55%;
  height: 2.4rem;
  font-size: 14px;
  padding: 0 10px;
  border-radius: 4px;
  border: ${(props) =>
    props.color === "error"
      ? "1px solid var(--error)"
      : "1px solid var(--primary)"};

  &:focus {
    outline: none;
  }
`;

export const InputArea = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const ButtonArea = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 30%;
  padding: 20px;
  margin-top: 1vw;
`;

export const Button = styled.button.attrs(() => {
  "submit";
})`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 45%;
  height: 45px;
  background: ${({ isDisabled }) =>
    isDisabled
      ? "var(--gray2)"
      : "linear-gradient(to right, var(--primary), var(--secondary))"};
  border-radius: 10px;
  border: none;
  padding: 10px;
  font-size: 15px;
  font-weight: bold;
  color: white;
  margin-bottom: 10px;
  cursor: ${({ isDisabled }) => (isDisabled ? "default" : "pointer")};

  &:hover {
    filter: ${({ isDisabled }) => (isDisabled ? "" : "brightness(1.2)")};
  }
`;

export const HelperText = styled.span`
  color: var(--error);
  font-size: 12px;
  width: 55%;
  height: 5%;
`;

export const Image = styled.img`
  width: auto;
  height: 55px;
`;

export const InputAreaPassword = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
`;

export const ContainerPassword = styled.div`
  display: flex;
  width: 55%;
  border: ${(props) =>
    props.color === "error"
      ? "1px solid var(--error)"
      : "1px solid var(--primary)"};

  border-radius: 4px;
  justify-content: center;
  &:focus {
    outline: none;
  }
`;

export const FormInputPassword = styled.input`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  height: 2.4rem;
  font-size: 14px;
  padding: 0 10px;
  border: none;
  background: transparent;
  &:focus {
    outline: none;
  }
`;

export const IconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  color: var(--gray2);
  margin-right: 5px;
`;

export const BoxInputsSuccess = styled.div`
  width: 100%;
  max-width: 30rem;
  display: flex;
  flex-flow: column nowrap;
  margin-top: 10px;
`;

export const InputAreaContainerSuccess = styled.div`
  width: 100%;
  height: 16.25rem;
  display: flex;
  flex-direction: column;
`;

export const TextContainerSuccess = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  margin-top: 10px;
`;

export const TitleSuccess = styled.text`
  display: flex;
  color: var(--gray5);
  font-weight: 700;
  font-size: 1.5rem;
  width: 100%;
  text-align: center;
  align-items: center;
  justify-content: center;
`;

export const SubTitleSuccess = styled.text`
  display: flex;
  width: 100%;
  color: var(--gray5);
  font-size: 1rem;
  font-weight: 700;
  margin-top: 30px;
  text-align: center;
  align-items: center;
  justify-content: center;
`;
