import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background: var(--backgroundPrimary);
`;

export const ToastContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const BoxArea = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 50vw;
  height: 90%;
  background: var(--backgroundSecondary);
  border-radius: 15px;
  min-width: 400px !important;
`;

export const Header = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 12vh;
  background-image: linear-gradient(to right, var(--primary), var(--secondary));
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  padding: 20px;
`;

export const Icon = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 90px;
  height: 90px;
  background: var(--backgroundSecondary);
  border-radius: 50%;
  border: none;
  margin-bottom: -12vh;
  box-shadow: 0px 0px 10px var(--primary);
`;

export const ContainerCardTitle = styled.div`
  margin-top: 80px;
`;

export const CardTitle = styled.h1`
  color: var(--primary);
  font-size: 29px;
`;

export const FormArea = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 60%;
  padding: 20px;
`;

export const ButtonArea = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 30%;
  padding: 20px;
  margin-top: 1vw;
`;

export const Button = styled.button.attrs(() => {
  "submit";
})`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 45%;
  height: 45px;
  background: ${({ isDisabled }) =>
    isDisabled
      ? "var(--gray2)"
      : "linear-gradient(to right, var(--primary), var(--secondary))"};
  border-radius: 10px;
  border: none;
  padding: 10px;
  font-size: 15px;
  font-weight: bold;
  color: white;
  margin-bottom: 10px;
  cursor: pointer;

  &:hover {
    filter: ${({ isDisabled }) => (isDisabled ? "" : "brightness(1.2)")};
  }
`;

export const ButtonText = styled.div`
  padding: 10px;
  font-size: 15px;
  font-weight: bold;
  color: var(--primary);
  cursor: pointer;
`;

export const Image = styled.img`
  width: auto;
  height: 55px;
`;
