import styled from "styled-components";

export const DigitCode = styled.div`
  display: flex;
`;

export const DigitInput = styled.input`
  min-width: auto;
  min-height: auto;
  width: 48px;
  height: 72px;
  display: flex;
  border-radius: 8px;
  align-items: center;
  justify-content: center;
  display: flex;
  border: 1px solid
    ${(props) =>
      (props.color === "error" && "var(--error)") ||
      (props.color === "default" && "var(--primary)")};
  text-align: center;
  caret-color: var(--primary);
  background-color: transparent;
  color: var(--white);
  font-size: 35px;
  font-weight: 400;
  margin-right: 24px;
  &:focus {
    outline: none;
  }
  &:last-child {
    margin-right: 0;
  }
`;

export const HelperText = styled.span`
  color: ${(props) =>
    (props.color === "success" && "var(--success)") ||
    (props.color === "error" && "var(--error)") ||
    (props.color === "default" && "transparent")};
  font-size: 1rem;
  margin-top: 5%;
  width: 100%;
  height: 10%;
  display: flex;
  align-items: center;
`;
