import React from "react";
import Cookie from "js-cookie";
import { IFormValues } from "../../interfaces/Form.structure";
import LoginService from "../../services/Login.service";
import emailChecker from "../../helper/validators";
import Globals from "../../globals";
import logo from "../../assets/logo.png";
import Toast from "../../components/Toast";
import Loading from "../../components/Loading";
import { Link, useNavigate } from "react-router-dom";
import { InputEmail, InputPassword } from "../../components/Inputs";
import {
  BoxArea,
  Button,
  ButtonArea,
  ButtonText,
  CardTitle,
  Container,
  ContainerCardTitle,
  FormArea,
  Header,
  Icon,
  Image,
  ToastContainer,
} from "./styles";

export default function Login() {
  const navigate = useNavigate();
  const [isDisabled, setIsDisabled] = React.useState<boolean>(false);
  const [loading, setLoading] = React.useState<boolean>(false);
  const [values, setValues] = React.useState<IFormValues>({
    email: "",
    password: "",
    showPassword: false,
  });
  const [error, setError] = React.useState<boolean>(false);
  const [isError] = React.useState<boolean>(false);
  const [list, setList] = React.useState<Array<string | number>>([]);
  let toastProperties: string | any = [];

  const eventButton = (e) => {
    if (e.key === "Enter" || e.key === "NumpadEnter") {
      login();
    }
  };
  const showToast = (isError: boolean) => {
    if (isError) {
      toastProperties = {
        id: list.length + 1,
        title: "Usuário ou senha inválida!",
      };
      setList([...list, toastProperties]);
    } else {
      toastProperties = [];
    }
  };

  async function login() {
    setLoading(true);
    const result = await LoginService.login(
      values.email.trim(),
      values.password
    );

    if (
      result.message ===
      "Primeiro acesso! Redirecione esse usuário para redefinir a senha!"
    ) {
      setError(false);
      setLoading(false);
      localStorage.setItem('email', values.email)
      navigate( "/first-access", {state: { email: values.email }})
      return;
    }
    if (result.message) {
      setError(true);
      showToast(!isError);
      setLoading(false);
      return;
    }

    setError(false);
    setLoading(false);

    Cookie.set("hub_auth", JSON.stringify(result));
    Cookie.set("token", result.token);
    Cookie.set("id", result.id);
    window.location.href = "/hub";
  }

  React.useEffect(() => {
    setIsDisabled(
      loading || emailChecker(values.email) || values.password.length <= 1
    );
  }, [loading, values.password, values.email, emailChecker]);

  return (
    <>
      <Globals />
      <ToastContainer>
        <Toast toastlist={list} setList={setList} />
      </ToastContainer>
      <Container>
        <BoxArea>
          <Header>
            <Icon>
              <Image src={logo} alt="logo" width="auto" height={55} />
            </Icon>
          </Header>

          <ContainerCardTitle>
            <CardTitle>Sistemas Internos</CardTitle>
          </ContainerCardTitle>

          <FormArea>
            <InputEmail
              label="E-mail"
              value={values.email}
              error={error}
              onKeyDown={(e) => eventButton(e)}
              onChange={(event) => {
                setValues({ ...values, email: event.target.value });
                setError(false);
              }}
            />

            <InputPassword
              label="Senha"
              value={values.password}
              onChange={(event) => {
                setValues({ ...values, password: event.target.value });
                setError(false);
              }}
              onKeyDown={(e) => eventButton(e)}
              error={error}
              showPassword={values.showPassword}
              onClickShowPassword={() => {
                setValues({
                  ...values,
                  showPassword: !values.showPassword,
                });
              }}
            />
          </FormArea>

          <ButtonArea>
            <Button
              onClick={login}
              disabled={isDisabled}
              isDisabled={isDisabled}
              type="submit"
            >
              {loading ? <Loading /> : "Entrar"}
            </Button>
            <Link to={`/reset-password`}>
              <ButtonText>Esqueci minha senha</ButtonText>
            </Link>
          </ButtonArea>
        </BoxArea>
      </Container>
    </>
  );
}
