import styled, { keyframes } from "styled-components";

const toastIn = keyframes`
    to {
      opacity: 0.9;
    }
    from {
        opacity: 0;
    }
`;

export const Container = styled.div`
  position: fixed;
  align-self: center;
  align-items: center;
  z-index: 10;
  height: 100%;
  max-width: 35%;
`;

export const NotificationContainer = styled.div`
  display: flex;
  width: 100%;
  height: 6%;
  border-radius: 5px;
  background-color: var(--error);
  animation: ${toastIn} 0.5s;
  @media only screen and (max-width: 1400px) {
    height: 10%;
  }
`;

export const TextContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  margin-top: 6px;
  margin-right: 5px;
`;

export const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  width: fit-content;
  height: fit-content;
`;

export const Button = styled.button`
  background: none;
  border: none;
  color: #ffffff;
  width: 15%;
  font-size: 2rem;
  cursor: pointer;
`;

export const TitleContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const Title = styled.span`
  font-size: 1rem;
  font-weight: 700;
  margin-bottom: 10px;
  color: #ffffff;
`;
