import styled from "styled-components";

export const InputArea = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const InputBox = styled.div`
  width:55%;
  min-height:2.4rem;
  height: fit-content;
  position: relative;
` 

export const FormLabel = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 55%;
  height: 30px;
  border-radius: 15px;
  border: none;
  margin-bottom: 5px;
  font-size: 14px;
  font-weight: bold;
  color: var(--gray8);
`;

export const FormInput = styled.input`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  height: 100%;
  font-size: 14px;
  padding: 0 10px;
  border-radius: 4px;
  border: ${(props) =>
    props.color === "error"
      ? "1px solid var(--error)"
      : "1px solid var(--primary)"};

  &:focus {
    outline: none;
  }
`;

export const HelperText = styled.span`
  color: var(--error);
  font-size: 12px;
  width: 55%;
  margin-top: 5px;
  position: absolute;
  bottom: -50%;
  left:5px;
`;
