import { FaCheck } from "react-icons/fa";
import { IModal } from "./modal.structure";
import * as Styles from "./styles";

const ModalSuccess = ({ content }: IModal) => {
  return (
    <Styles.Container>
      <Styles.Modal>
        <Styles.ContainerIcon>
          <FaCheck size={130} />
        </Styles.ContainerIcon>

        {content}
      </Styles.Modal>
    </Styles.Container>
  );
};

export default ModalSuccess;
