import React from "react";
import emailChecker from "../../../helper/validators";
import { IEmail } from "./email.structure";
import * as Styles from "./styles";

export const InputEmail: React.FC<IEmail> = ({ label, onKeyDown, value, onChange, error }) => {
  return (
    <Styles.InputArea>
      <Styles.FormLabel>{label}</Styles.FormLabel>
      <Styles.InputBox>
        <Styles.FormInput
          color={(emailChecker(value) && value) || error ? "error" : ""}
          onKeyDown={onKeyDown}
          value={value}
          onChange={onChange}
        />
        {emailChecker(value) && value && (
          <Styles.HelperText>E-mail Inválido!</Styles.HelperText>
        )}
      </Styles.InputBox>

    </Styles.InputArea>
  );
};
