import React, { useEffect } from "react";
import Globals from "../../globals";
import Toast from "../../components/Toast";
import { InputPassword } from "../../components/Inputs";
import logo from "../../assets/logo.png";
import {
  BoxArea,
  Button,
  ButtonArea,
  CardSubTitle,
  CardTitle,
  Container,
  ContainerCardTitle,
  FormArea,
  Header,
  Icon,
  Image,
  ToastContainer,
} from "./styles";
import Cookie from "js-cookie";
import FirstLoginService from "../../services/FirstLogin.service";
import Loading from "../../components/Loading";
import ErrorNotification from "../../components/ErrorNotification";
import LoginService from "../../services/Login.service";
import { useLocation } from "react-router-dom";

export default function FirstAccess() {
  const [oldPassword, setOldPassword] = React.useState<string>("");
  const [newPassword, setNewPassword] = React.useState<string>("");
  const [confirmPassword, setConfirmPassword] = React.useState<string>("");
  const [passwordToShow, setPasswordToShow] = React.useState<string>("");
  const [loading, setLoading] = React.useState<boolean>(false);
  const [error, setError] = React.useState<boolean>(false);

  const isDisabled =
    oldPassword.length < 3 ||
    newPassword.length < 3 ||
    confirmPassword.length < 3 ||
    newPassword !== confirmPassword;

  const userData = useLocation().state as { email: string };
  
  async function firstLogin() {
    setLoading(true);
    const result = await FirstLoginService.firstLogin(
      userData.email,
      oldPassword,
      newPassword
    );
    if (result.error) {
      setError(true);
      setLoading(false);
      return;
    }
    setError(false);
    setLoading(false);
    window.location.href = "/";
  }

  return (
    <>
      <Globals />
      <ToastContainer>
        {error && (
          <ErrorNotification
            text={"Senha atual incorreta ou nova senha inválida"}
            setIsOpen={setError}
          />
        )}
      </ToastContainer>
      <Container>
        <BoxArea>
          <Header>
            <Icon>
              <Image src={logo} alt="logo" width="auto" height={55} />
            </Icon>
          </Header>

          <ContainerCardTitle>
            <CardTitle>Nova Senha</CardTitle>
            <CardSubTitle>Crie uma nova senha de acesso</CardSubTitle>
          </ContainerCardTitle>

          <FormArea>
            <InputPassword
              label="Insira sua senha atual"
              value={oldPassword}
              onChange={(event) => {
                setOldPassword(event.target.value);
              }}
              error={error}
              showPassword={passwordToShow === "old"}
              onKeyDown={() => {}}
              onClickShowPassword={
                passwordToShow === "old"
                  ? () => setPasswordToShow("")
                  : () => setPasswordToShow("old")
              }
            />
            <InputPassword
              label="Insira sua nova senha"
              value={newPassword}
              onChange={(event) => {
                setNewPassword(event.target.value);
              }}
              error={error}
              showPassword={passwordToShow === "new"}
              onKeyDown={() => {}}
              onClickShowPassword={
                passwordToShow === "new"
                  ? () => setPasswordToShow("")
                  : () => setPasswordToShow("new")
              }
            />
            <InputPassword
              label="Repita sua nova senha"
              value={confirmPassword}
              onChange={(event) => {
                setConfirmPassword(event.target.value);
              }}
              error={
                confirmPassword !== newPassword && confirmPassword.length > 0
              }
              showPassword={passwordToShow === "confirm"}
              onKeyDown={() => {}}
              helperText={
                confirmPassword !== newPassword && confirmPassword.length > 0
                  ? "As senhas não são iguais"
                  : ""
              }
              onClickShowPassword={
                passwordToShow === "confirm"
                  ? () => setPasswordToShow("")
                  : () => setPasswordToShow("confirm")
              }
            />
          </FormArea>

          <ButtonArea>
            <Button
              onClick={() => firstLogin()}
              disabled={isDisabled}
              isDisabled={isDisabled}
            >
              {loading ? <Loading /> : "Criar nova senha"}
            </Button>
          </ButtonArea>
        </BoxArea>
      </Container>
    </>
  );
}
