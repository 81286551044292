import React from "react";
import emailChecker from "../../helper/validators";
import Cookie from "js-cookie";
import Globals from "../../globals";
import logo from "../../assets/logo.png";
import Toast from "../../components/Toast";
import Loading from "../../components/Loading";
import * as Styles from "./styles";
import sendCodeResetService from "../../services/sendCodeReset.service";
import InputDigits from "../../components/InputDigits";
import { BsCheckLg } from "react-icons/bs";
import ModalSuccess from "../../components/ModalSuccess";
import LoginService from "../../services/Login.service";
import { InputEmail, InputPassword } from "../../components/Inputs";

interface IResetPasswordHash {
  [key: number]: React.ReactNode;
}

export default function ResetPassword() {
  const [isDisabled, setIsDisabled] = React.useState<boolean>(false);
  const [loading, setLoading] = React.useState<boolean>(false);
  const [email, setEmail] = React.useState<string>("");
  const [error, setError] = React.useState<boolean>(false);
  const [list, setList] = React.useState<Array<string | number>>([]);
  const [stepsHash, setStepsHash] = React.useState<number>(1);
  const [modalHash, setModalHash] = React.useState<number>(0);
  const [confirmationCode, setConfirmationCode] = React.useState<string>("");
  const [newPassword, setNewPassword] = React.useState<string>("");
  const [confirmPassword, setConfirmPassword] = React.useState<string>("");
  const [showPasswordNew, setShowPasswordNew] = React.useState<boolean>(false);
  const [showPasswordConfirm, setShowPasswordConfirm] =
    React.useState<boolean>(false);
  const [disablePassword, setDisablePassword] = React.useState<boolean>(true);
  const [helperText, setHelperText] = React.useState<string>("");
  const [digitsStatus, setDigitsStatus] = React.useState<"default" | "error">(
    "default"
  );
  let toastProperties: string | any = [];

  const eventButton = (e) => {
    if (e.key === "Enter" || e.key === "NusetModalHashmpadEnter") {
      if (stepsHash === 1) {
        // sendEmail();
        setStepsHash(2);
      } else if (stepsHash === 2) {
        setStepsHash(3);
      } else if (stepsHash === 3) {
        // sendNewPassword();
        setModalHash(1);
      }
    }
  };

  const showToast = (isError: string) => {
    if (isError === "email") {
      toastProperties = {
        id: list.length + 1,
        title: "Email inválido, verifique se o email foi digitado corretamente",
      };
      setList([...list, toastProperties]);
    } else if (isError === "password") {
      toastProperties = {
        id: list.length + 1,
        title: "A senha digitada não atende os critérios de segurança!",
      };
      setList([...list, toastProperties]);
    } else if (isError === "code") {
      toastProperties = {
        id: list.length + 1,
        title:
          "Algo de errado aconteceu, confira se digitou o código corretamente!",
      };
      setList([...list, toastProperties]);
    } else if (isError === "login") {
      toastProperties = {
        id: list.length + 1,
        title: "Não foi possível fazer login, tente novamente mais tarde!",
      };
      setList([...list, toastProperties]);
    } else {
      toastProperties = [];
    }
  };

  async function sendEmail() {
    setLoading(true);
    const result = await sendCodeResetService.sendCodeReset(email);

    if (!result) {
      setError(true);
      showToast("email");
      setLoading(false);
      return;
    }

    setError(false);
    setLoading(false);

    setStepsHash(2);
  }
  async function validateCode() {
    setLoading(true);
    const result = await sendCodeResetService.validateCode(
      email,
      confirmationCode
    );

    if (!result) {
      setError(true);
      showToast("code");
      setLoading(false);
      return;
    }

    setError(false);
    setLoading(false);

    setStepsHash(3);
  }

  async function login() {
    setLoading(true);
    const result = await LoginService.login(email.trim(), newPassword);

    if (!result) {
      setError(true);
      showToast("login");
      setLoading(false);
      return;
    }
    const { token, refresh_token, id } = result;
    setError(false);
    setLoading(false);

    Cookie.set("hub_auth", JSON.stringify(result));
    Cookie.set('token', token);
    Cookie.set('id', id);  
    window.location.href = "/hub";
  }

  async function sendNewPassword() {
    setLoading(true);
    const result = await sendCodeResetService.resetPassword(
      email,
      confirmationCode,
      newPassword
    );
    if (result === "error") {
      setError(true);
      showToast("password");
      setLoading(false);
      return;
    }

    setError(false);
    setLoading(false);

    setModalHash(1);
    setTimeout(() => {
      login();
    }, 1000);
  }

  React.useEffect(() => {
    if (
      newPassword === confirmPassword &&
      newPassword !== "" &&
      confirmPassword !== ""
    ) {
      setDisablePassword(false);
      setDigitsStatus("default");
    } else {
      setDisablePassword(true);
    }
    if (
      newPassword !== confirmPassword &&
      newPassword !== "" &&
      confirmPassword !== ""
    ) {
      setDigitsStatus("error");
      setHelperText("As senhas não são iguais");
    } else {
      setHelperText("");
      setDigitsStatus("default");
    }
  }, [newPassword, confirmPassword, digitsStatus]);

  React.useEffect(() => {
    setIsDisabled(loading || emailChecker(email));
  }, [loading, email, emailChecker]);

  const StepsResetPassword: IResetPasswordHash = {
    1: (
      <>
        <Styles.ContainerCardTitle>
          <Styles.CardTitle>Esqueci a minha senha</Styles.CardTitle>
          <Styles.CardSubTitle>
            Digite o email utilizado para fazer o seu cadastro.
          </Styles.CardSubTitle>
        </Styles.ContainerCardTitle>

        <Styles.FormArea>
          <InputEmail
            label="E-mail"
            value={email}
            onChange={(e) => {
              setEmail(e.target.value);
              setError(false);
            }}
            error={error}
            onKeyDown={(e) => eventButton(e)}
          />
        </Styles.FormArea>

        <Styles.ButtonArea>
          <Styles.Button onClick={() => sendEmail()} type="submit">
            {loading ? <Loading /> : "Enviar Código"}
          </Styles.Button>
        </Styles.ButtonArea>
      </>
    ),
    2: (
      <>
        <Styles.ContainerCardTitle>
          <Styles.CardTitle>Código enviado!</Styles.CardTitle>
          <Styles.CardSubTitle>
            Um código foi enviado para o seu e-mail. Digite o código no campo
            abaixo para alterar sua senha.
          </Styles.CardSubTitle>
        </Styles.ContainerCardTitle>
        <Styles.FormArea>
          <InputDigits
            helper="Código invalido"
            isPassword={false}
            label="Codigo de confirmação"
            lenthOfDigits={5}
            setValue={setConfirmationCode}
            status={digitsStatus}
            value={confirmationCode.replace(" ", "")}
            onKeyDown={(e) => eventButton(e)}
          />
        </Styles.FormArea>
        <Styles.ButtonArea>
          <Styles.Button onClick={() => validateCode()} type="submit">
            {loading ? <Loading /> : "Confirmar código"}
          </Styles.Button>
        </Styles.ButtonArea>
      </>
    ),
    3: (
      <>
        <Styles.ContainerCardTitle>
          <Styles.CardTitle>
            Código validado! <BsCheckLg style={{ color: "var(--success)" }} />
          </Styles.CardTitle>
          <Styles.CardSubTitle>
            Digite agora sua nova senha de acesso!
          </Styles.CardSubTitle>
        </Styles.ContainerCardTitle>

        <Styles.FormArea>
          <InputPassword
            label="Insira sua nova senha"
            onKeyDown={(e) => eventButton(e)}
            value={newPassword}
            onChange={(e) => {
              setNewPassword(e.target.value);
              setError(false);
            }}
            error={digitsStatus === "error"}
            onClickShowPassword={() => {
              setShowPasswordNew(!showPasswordNew);
            }}
            showPassword={showPasswordNew}
          />

          <InputPassword
            label="Repita sua nova senha"
            onKeyDown={(e) => eventButton(e)}
            value={confirmPassword}
            onChange={(e) => {
              setConfirmPassword(e.target.value);
              setError(false);
            }}
            error={digitsStatus === "error"}
            onClickShowPassword={() => {
              setShowPasswordConfirm(!showPasswordConfirm);
            }}
            showPassword={showPasswordConfirm}
            helperText={helperText}
          />
        </Styles.FormArea>

        <Styles.ButtonArea>
          <Styles.Button
            isDisabled={
              newPassword !== confirmPassword ||
              newPassword === "" ||
              confirmPassword === ""
            }
            disabled={
              newPassword !== confirmPassword ||
              newPassword === "" ||
              confirmPassword === ""
            }
            onClick={() => sendNewPassword()}
            type="submit"
          >
            {loading ? <Loading /> : "Alterar senha"}
          </Styles.Button>
        </Styles.ButtonArea>
      </>
    ),
  };

  const ModalSuccessHash: IResetPasswordHash = {
    1: (
      <ModalSuccess
        content={
          <Styles.BoxInputsSuccess>
            <Styles.InputAreaContainerSuccess>
              <Styles.TextContainerSuccess>
                <Styles.TitleSuccess>
                  Sua senha foi alterada com sucesso!
                </Styles.TitleSuccess>
                <Styles.SubTitleSuccess>
                  Você será logado automaticamente.
                </Styles.SubTitleSuccess>
              </Styles.TextContainerSuccess>
            </Styles.InputAreaContainerSuccess>
          </Styles.BoxInputsSuccess>
        }
      />
    ),
  };

  return (
    <>
      <Globals />
      <Styles.ToastContainer>
        <Toast toastlist={list} setList={setList} />
      </Styles.ToastContainer>
      <Styles.Container>
        <Styles.BoxArea>
          <Styles.Header>
            <Styles.Icon>
              <Styles.Image src={logo} alt="logo" width="auto" height={55} />
            </Styles.Icon>
          </Styles.Header>

          {StepsResetPassword[stepsHash]}
          {ModalSuccessHash[modalHash]}
        </Styles.BoxArea>
      </Styles.Container>
    </>
  );
}
