import { IError } from "../interfaces/Error.structure";
import api from "./api";

class FirstLoginService {
  async firstLogin(email: string, oldPassword: string, newPassword: string) {
    const body = {
      email,
      oldPassword,
      newPassword,
    };

    try {
      const { data } = await api.patch("profile/firstAccess", body);
      return data;
    } catch (error) {
      return {
        error: true,
        message: error.message
      };
    }
  }
}

export default new FirstLoginService();
