import styled from "styled-components";

export const InputAreaPassword = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
`;

export const InputBox = styled.div`
  width:100%;
  min-height:2.4rem;
  height: fit-content;
  position: relative;
  display: flex;
` 


export const FormLabel = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 55%;
  height: 30px;
  border-radius: 15px;
  border: none;
  margin-bottom: 5px;
  font-size: 14px;
  font-weight: bold;
  color: var(--gray8);
`;

export const ContainerPassword = styled.div`
  display: flex;
  position: relative;
  width: 55%;
  border: ${(props) =>
    props.color === "error"
      ? "1px solid var(--error)"
      : "1px solid var(--primary)"};

  border-radius: 4px;
  justify-content: center;
  &:focus {
    outline: none;
  }
`;

export const FormInputPassword = styled.input`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  height: 2.4rem;
  font-size: 14px;
  padding: 0 10px;
  border: none;
  background: transparent;
  &:focus {
    outline: none;
  }
`;

export const IconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  color: var(--gray2);
  margin-right: 5px;
`;

export const HelperText = styled.span`
  color: var(--error);
  font-size: 12px;
  width: 55%;
  height: 5%;
  position: absolute;
  bottom: -10px;
  left: 0;
`;

