import axios from "axios";
import Cookie from "js-cookie";

const api = axios.create({
  baseURL: process.env.URL_LOGIN,
});

api.interceptors.request.use( async config => {
  const auth = Cookie.get('hub_auth');
  const token = auth ? JSON.parse(auth).token : null;
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
})

export default api;
