import React from "react";
import { MdOutlineVisibility, MdOutlineVisibilityOff } from "react-icons/md";
import { IPassword } from "./password.structure";
import * as Styles from "./styles";

export const InputPassword: React.FC<IPassword> = ({
  label,
  error,
  value,
  showPassword,
  onKeyDown,
  onChange,
  onClickShowPassword,
  helperText
}) => {
  return (
    <Styles.InputAreaPassword>
      <Styles.FormLabel>{label}</Styles.FormLabel>
      <Styles.ContainerPassword color={error ? "error" : ""}>
        <Styles.InputBox>
          <Styles.FormInputPassword
            type={showPassword ? "text" : "password"}
            onKeyDown={onKeyDown}
            value={value}
            onChange={onChange}
          />

          <Styles.IconContainer onClick={onClickShowPassword}>
          {showPassword ? (
            <MdOutlineVisibilityOff size={26} />
            ) : (
            <MdOutlineVisibility size={26} />
            )}
          </Styles.IconContainer>
          <Styles.HelperText>{helperText}</Styles.HelperText>

        </Styles.InputBox>


      </Styles.ContainerPassword>
    </Styles.InputAreaPassword>
  );
};
