import React from "react";
import { RiCloseFill } from "react-icons/ri";
import * as Styles from "./styles";

const ErrorNotification = ({
  text,
  setIsOpen,
}: {
  text: string;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  return (
    <Styles.NotificationContainer>
      <Styles.TextContainer>
        <Styles.Button onClick={() => setIsOpen(false)}>
          <RiCloseFill />
        </Styles.Button>
        <Styles.TitleContainer>
          <Styles.Title>{text}</Styles.Title>
        </Styles.TitleContainer>
      </Styles.TextContainer>
    </Styles.NotificationContainer>
  );
};

export default ErrorNotification;
