import React from "react";
import { specialCharCheck } from "../../helper/validators";
import { IDigitCodeInput } from "./inputDigitsStructure";
import { DigitCode, DigitInput, HelperText } from "./styles";

const InputDigits: React.FC<IDigitCodeInput> = ({
  lenthOfDigits = 5,
  setValue,
  status = "default",
  isPassword = true,
  helper,
  onKeyDown,
}) => {
  const [digits, setDigits] = React.useState<string[]>(
    Array(lenthOfDigits).fill("")
  );
  const currentRef = React.useRef<any>([]);
  const showDigits = React.useMemo(() => {
    return digits.map((value, index) => {
      return (
        <DigitInput
          key={index}
          ref={(element) => {
            currentRef.current[index] = element;
          }}
          onKeyDown={onKeyDown}
          color={status}
          maxLength={1}
          type={isPassword ? "password" : "text"}
          value={value}
          onChange={(event) => {
              const newDigits = [...digits];
              newDigits[index] = event.target.value.toUpperCase();
              setDigits(newDigits);
              setValue(newDigits.join(""));

              if (digits[index].length === 1) {
                currentRef.current[index - 1].focus();
              }

              if (digits[index].length === 0) {
                currentRef.current[index + 1].focus();
              }
            
          }}
        />
      );
    });
  }, [digits, lenthOfDigits, status]);


  return (
    <>
      <DigitCode>{showDigits}</DigitCode>
      {status === "error" && <HelperText color={status}>{helper}</HelperText>}
    </>
  );
};

export default InputDigits;
